<script src="../tools/wxapp_rsa.js"></script>
<template>
  <div id="mainPage">
    <div class="topBC">
      <img src="../static/images/icon_charge_title.png" width="55%" height="25px"/>
      <div class="title">
        <span>用户充值</span>
        <img src="../static/images/logo_goAllOutMan.png" width="69px" height="86px"/>
        <em v-if="false" class="button" @click="onGoNewPage('testMemberNavi')">主页</em>
      </div>
    </div>

    <!--预约内容区域-->
    <div class="context">
      <!--检索区域-->
      <div class="area_search">
        <input type="text" class="phone" pattern=[0-9]* maxlength="11" v-model="phone"
               :onchange="getUserList()" placeholder="请输入手机号查询所属商家"/>
        <ul class="memberList" v-if="phone.length == 11">
          <li v-for="item in userList" @click="selectUser(item)">
            <input type="checkbox" :checked="item.ShopId == userSelect.ShopId"/>
            <span>{{ item.ShopName.length > 7 ? item.ShopName.slice(5) : item.ShopName.slice(3) }}</span>
            <em>{{ item.UserName }}</em>
          </li>

        </ul>
      </div>

      <!--活动TAB区域-->
      <div class="area_tab">
        <ul>
          <li v-for="item in RsTab"
              :class="[{'tab_select':tabSelect.PId == item.PId}]"
              @click="selectTab(item)">
            {{ item.Name }}
          </li>
        </ul>
      </div>

      <!--会籍区域-->
      <div class="area_member" v-if="RsMonth.length > 0">
        <div class="banner">
          <span>会籍</span>
          <em>请选择入会月数</em>
        </div>
        <ul class="chargeOption">
          <li v-for="item in RsMonth"
              :class="[{'activeLi':monthSelect.PdId == item.PdId}]"
              @click="item.isDisabled ? alertInfo('您不能购买该项'):selectMonth(item)">
            <em class="month">{{ item.EffectiveMonths }}个月</em>
            <div class="cash" :class="[{'cashActive':monthSelect.PdId == item.PdId}]">
              ￥<em :class="[{'emActive':monthSelect.PdId == item.PdId}]">{{ item.Price / 100 }}</em>
            </div>
            <div class="gift" :class="[{'giftActive':monthSelect.PdId == item.PdId}]">
              <div v-if="item.GiftMonths > 0">赠送{{ item.GiftMonths }}个月</div>
              <div v-else-if="item.GiftHour > 0">赠送{{ item.GiftHour }}课时</div>
              <div v-else>无赠送</div>
            </div>
          </li>
        </ul>
      </div>

      <!--课时区域-->
      <div class="area_member" v-if="RsHour.length > 0">
        <div class="banner_hour">
          <span>课时</span>
          <em>请选择课时数</em>
        </div>
        <ul class="chargeOption">
          <li v-for="item in RsHour"
              :class="[{'activeLi':hourSelect.PdId == item.PdId}]"
              @click="item.isDisabled ? alertInfo('您不能购买该项'):selectHour(item)">
            <em class="month">{{ item.NormalHour }}课时</em>
            <div class="cash" :class="[{'cashActive':hourSelect.PdId == item.PdId}]">
              ￥<em :class="[{'emActive':hourSelect.PdId == item.PdId}]">{{ item.Price / 100 }}</em>
            </div>
            <div class="gift" :class="[{'giftActive':hourSelect.PdId == item.PdId}]">
              <div v-if="item.GiftMonths > 0">赠送{{ item.GiftMonths }}个月</div>
              <div v-else-if="item.GiftHour > 0">赠送{{ item.GiftHour }}课时</div>
              <div v-else>无赠送</div>
            </div>
          </li>
        </ul>
      </div>

      <!--限时活动-->
      <div class="area_member" v-if="RsValidity.length > 0">
        <div class="banner_hour">
          <span>限时活动</span>
          <em>请选择课时数</em>
        </div>
        <ul class="chargeOption">
          <li v-for="item in RsValidity"
              :class="[{'activeLi':validitySelect.PdId == item.PdId}]"
              @click="item.isDisabled ? alertInfo('您不能购买该项'):selectValidity(item)">
            <em class="month">{{ item.PdName.slice(0,6) }}</em>
            <div class="cash" :class="[{'cashActive':validitySelect.PdId == item.PdId}]">
              ￥<em :class="[{'emActive':validitySelect.PdId == item.PdId}]">{{ item.Price / 100 }}</em>
            </div>
            <div class="dateExpire" :class="[{'dateExpireActive':validitySelect.PdId == item.PdId}]">
              <div>到期{{ item.EndTime |filterTime }}</div>
            </div>
          </li>
        </ul>
      </div>

      <!--特殊活动包-->
      <div class="area_member" v-if="RsSpecial.length > 0">
        <div class="banner_hour">
          <span>特殊活动</span>
          <em>请选择课时数</em>
        </div>
        <ul class="chargeOption">
          <li v-for="item in RsSpecial"
              :class="[{'activeLi':specialSelect.PdId == item.PdId}]"
              @click="item.isDisabled ? alertInfo('您不能购买该项'):selectSpecial(item)">
            <em class="month">{{ item.PdName}}</em>
            <div class="cash" :class="[{'cashActive':specialSelect.PdId == item.PdId}]">
              ￥<em :class="[{'emActive':specialSelect.PdId == item.PdId}]">{{ item.Price / 100 }}</em>
            </div>
            <div class="dateExpire" :class="[{'dateExpireActive':specialSelect.PdId == item.PdId}]">
              <div>到期{{ item.EndTime |filterTime }}</div>
            </div>
          </li>
        </ul>
      </div>

      <!--结算区域-->
      <div class="area_account">
        <div class="title">充值金额</div>
        <div class="right_cash">
          ￥<em class="money">{{ cashTotal }}</em>
          <em class="memo">{{ giftTips }}</em>
        </div>
        <div class="right_code" v-if="true">
          <input v-model="inviteCode" @input="toUpper" class="inviteCode" placeholder="输入邀请码有惊喜" maxlength="6"></input>
          <em class="memo">首次充值 赠送</em>
          <em class="memo" style="color: #f39034">2</em>
          <em class="memo">课时</em>
        </div>
        <div class="button" @click="doBought()">
          充值
        </div>
        <div class="chargeExplain">
          <div v-html="Description"/>
        </div>

      </div>
    </div>

    <alert ref="alertPart"></alert>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script>
<script>
import {
  CurrentPromotionsTabQuery,
  PhoneRelationShopQuery,
  CurrentPromotionsQuery,
  JsApiPrepayRequest,
  GetOpenIdAndPhoneByCode
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

let qs = require('qs');

export default {
  data() {
    return {
      openId: '',
      phone: '',
      phoneQuery: '',
      inviteCode: '',
      userList: [],
      tabSelect: '',
      userSelect: '',
      monthSelect: '',
      hourSelect: '',
      validitySelect: '',
      specialSelect: '',
      giftTips: '无赠送',
      cashTotal: '0',
      RsTab: [],
      RsMonth: [],
      RsHour: [],
      RsValidity: [],
      RsSpecial: [],
      Description: '',
    }
  },
  mounted() {
    this.checkCode();
  },
  destroyed() {
  },
  filters: {
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 11);
      }
    },
  },
  watch: {
    $route(to) {
      if (to.name == 'membershipCharge') {
        this.checkCode();
      }
    },
    'active'() {
      this.getList();
    }
  },
  methods: {
    toUpper(event) {
      this.inviteCode = event.target.value.toUpperCase();
    },
    onChange(date) {
      console.log(date.format('YY-MM-DD'));
    },
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    confirmDay(car) {
      console.log(globaltime2StringNoMin(car));
    },
    selectUser(userObj) {
      if (userObj.ShopId == this.userSelect.ShopId) {
        this.userSelect = '';
      } else {
        this.userSelect = userObj;
        this.getChargeList();
      }
    },
    selectTab(tabObj) {
      if (tabObj.PId == this.tabSelect.PId) {
        this.tabSelect = '';
      } else {
        //释放选中的充值活动
        this.monthSelect = '';
        this.hourSelect = '';
        this.validitySelect = '';
        this.specialSelect = '';
        //释放计算的金额
        this.calcCash();
        //选中tab栏
        this.tabSelect = tabObj;
        //获取tab栏对应的充值活动
        this.getChargeList();
      }
    },
    selectMonth(monthObj) {
      if (monthObj.PdId == this.monthSelect.PdId) {
        this.monthSelect = '';
      } else {
        this.monthSelect = monthObj;
      }
      this.calcCash();
    },
    selectHour(hourObj) {
      if (hourObj.PdId == this.hourSelect.PdId) {
        this.hourSelect = '';
      } else {
        this.hourSelect = hourObj;
      }
      this.calcCash();
    },
    selectValidity(validObj) {
      if (validObj.PdId == this.validitySelect.PdId) {
        this.validitySelect = '';
      } else {
        this.validitySelect = validObj;
      }
      this.calcCash();
    },
    selectSpecial(validObj) {
      if (validObj.PdId == this.specialSelect.PdId) {
        this.specialSelect = '';
      } else {
        this.specialSelect = validObj;
      }
      this.calcCash();
    },
    // 获取CODE
    checkCode() {
      if (!localStorage.openId) {
        //取到openId
        if (this.getUrlKey('state') == 123) {
          let code = this.getUrlKey('code');//获取openCode
          //查询openId和phone
          this.getOpenId(code);
          //查询充值优惠
          this.getChargeTab();
        } else {
          this.getOpenCode();
        }
      } else {
        if (localStorage.phone) {
          this.phone = localStorage.phone;
        }
        //查询充值优惠
        this.getChargeTab();
      }
    },
    // 获取公众号ID
    getOpenCode() {
      console.log('获取公众号ID');
      //组装所需数据
      let origin = location.href;//http://wx.xfeilong.com
      let urlNow = encodeURIComponent(origin);
      let scope = 'snsapi_userinfo';//snsapi_userinfo 静默授权 用户无感知
      let appid = 'wx36cb8b6661d71811';//正式
      let state = '123';
      let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + urlNow + '&response_type=code&scope=' + scope + '&state=' + state + '#wechat_redirect';
      // 跳转地址
      self.location = url;
    },
    getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    },
    //通过openCode获取openId
    getOpenId(openCode) {
      let that = this;
      let param = {
        code: openCode
      };
      let postdata = qs.stringify(param);
      GetOpenIdAndPhoneByCode(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          if (json.OpenId != '') {
            localStorage.openId = json.OpenId;
          } else {
            localStorage.removeItem('openId');
          }
          if (json.Phone != '') {
            localStorage.phone = json.Phone;
          } else {
            localStorage.removeItem('phone');
          }
        } else {
          localStorage.removeItem('phone');
          localStorage.removeItem('openId');
        }

        //加载用户列表
        if (localStorage.phone != null) {
          that.phone = localStorage.phone;
          this.getUserList();
        }
      })
    },
    //加载用户列表
    getUserList() {
      let that = this;
      if (that.phone.toString().length == 11) {
        if (that.phoneQuery == that.phone) {
          return;
        }
        let param = {
          phone: that.phone
        };
        let postdata = qs.stringify(param);
        PhoneRelationShopQuery(postdata).then(res => {
          that.userList = [];
          that.phoneQuery = that.phone;
          let json = res;
          if (json.Code == 0) {
            if (json.Rs) {
              that.userList = json.Rs;
            }
          }
        })
      }
    },
    //结算
    calcCash() {
      //计算金额
      this.cashTotal = 0;
      if (this.monthSelect) {
        this.cashTotal += this.monthSelect.Price;
      }
      if (this.hourSelect) {
        this.cashTotal += this.hourSelect.Price;
      }
      if (this.validitySelect) {
        this.cashTotal += this.validitySelect.Price;
      }
      if (this.specialSelect) {
        this.cashTotal += this.specialSelect.Price;
      }
      this.cashTotal /= 100;

      //计算赠送选项
      if (this.monthSelect.GiftMonths > 0 || this.monthSelect.GiftHour > 0
          || this.hourSelect.GiftMonths > 0 || this.hourSelect.GiftHour > 0
          || this.validitySelect.GiftMonths > 0 || this.validitySelect.GiftHour > 0
          || this.specialSelect.GiftMonths > 0 || this.specialSelect.GiftHour > 0) {

        this.giftTips = '含赠送';
        let giftTotalMonth = 0;
        let giftTotalHour = 0;
        if (this.monthSelect) {
          giftTotalMonth += this.monthSelect.GiftMonths;
          giftTotalHour += this.monthSelect.GiftHour;
        }
        if (this.hourSelect) {
          giftTotalMonth += this.hourSelect.GiftMonths;
          giftTotalHour += this.hourSelect.GiftHour;
        }
        if (this.validitySelect) {
          giftTotalMonth += this.validitySelect.GiftMonths;
          giftTotalHour += this.validitySelect.GiftHour;
        }
        if (this.specialSelect) {
          giftTotalMonth += this.specialSelect.GiftMonths;
          giftTotalHour += this.specialSelect.GiftHour;
        }

        if (giftTotalMonth > 0) {
          this.giftTips += giftTotalMonth + '个月';
        }
        if (giftTotalMonth > 0 && giftTotalHour > 0) {
          this.giftTips += '+';
        }
        if (giftTotalHour > 0) {
          this.giftTips += giftTotalHour + '课时';
        }
      } else {
        this.giftTips = '无赠送';
      }
    },
    //获取充值活动tab
    getChargeTab() {
      let that = this;
      let param = {
      };
      let postdata = qs.stringify(param);
      CurrentPromotionsTabQuery(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          if (json.Rs.length > 0) {
            that.RsTab = json.Rs;
            that.tabSelect = that.RsTab[0];
            that.getChargeList();
          }
        }
      })
    },
    //获取可购买选项卡
    getChargeList() {
      let that = this;
      let param = {
        pId : that.tabSelect.PId,
        userId : that.userSelect.UserId
      };
      let postdata = qs.stringify(param);
      CurrentPromotionsQuery(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.Description = json.Description;

          if (json.RsClassHour.length > 0) {
            that.RsHour = json.RsClassHour;
          }else{
            that.RsHour = [];
          }
          if (json.RsMonth.length > 0) {
            that.RsMonth = json.RsMonth;
          }else{
            that.RsMonth = [];
          }
          if (json.RsValidityClassHour.length > 0) {
            that.RsValidity = json.RsValidityClassHour;
          }else{
            that.RsValidity = [];
          }
          if (json.RsSpecial.length > 0) {
            that.RsSpecial = json.RsSpecial;
          }else{
            that.RsSpecial = [];
          }
        } else {
          this.getChargeList()
        }
      })
    },
    // 处理购买请求
    doBought() {
      let that = this;
      if (!that.userSelect) {
        that.Toast('请选择充值会员');
        return;
      }
      if (!that.monthSelect && !that.hourSelect && !that.validitySelect&& !that.specialSelect) {
        that.Toast('请选择充值选项');
        return;
      }
      //拼接优惠活动
      let pdIdString = [];
      if (that.monthSelect) {
        pdIdString.push(that.monthSelect.PdId);
      }
      if (that.hourSelect) {
        pdIdString.push(that.hourSelect.PdId);
      }
      if (that.validitySelect) {
        pdIdString.push(that.validitySelect.PdId);
      }
      if (that.specialSelect) {
        pdIdString.push(that.specialSelect.PdId);
      }
      let param = {
        phone: that.phone,
        openId: localStorage.openId,
        shopId: that.userSelect.ShopId,
        pdIdStr: pdIdString.toString(),
        amount: that.cashTotal * 100,
        inviteCode: that.inviteCode
      };
      let postData = qs.stringify(param);
      JsApiPrepayRequest(postData).then(res => {
        let json = res;
        console.log(json);
        if (json.Code == 0) {
          let data = json.Rs;

          // 判断WeixinJSBridge环境、解决WeiXinJSBridge is not defined 报错的方法
          if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(data), false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(data));
              document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(data));
            }
          } else {
            console.log('准备调用微信支付')
            that.onBridgeReady(data);
          }
        }
      })
    },
    onBridgeReady(data) {
      console.log('onBridgeReady');
      let that = this;

      WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": data.AppId,     //公众号ID，由商户传入
            "timeStamp": data.TimeStamp,     //时间戳，自1970年以来的秒数
            "nonceStr": data.NonceStr,      //随机串
            "package": data.Package,
            "signType": data.SignType,     //微信签名方式：
            "paySign": data.PaySign //微信签名
          },
          function (res) {
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              console.log('支付成功,正在查询结果');
              that.Toast('支付成功,正在查询结果');
              let OutTradeNo = data.OutTradeNo;//商家订单交易编号
              //拿着订单号去充值结果页查询交易结果
              that.$router.push({
                path: '/membershipChargeResult', query: {OutTradeNo: OutTradeNo}
              });
            } else {
              that.Toast('支付失败');
            }
          }
      );

    },
    onGoNewPage(path) {
      this.$router.push({path: '/' + path});
    },
  },
  beforeDestroy() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
        vm.getList();
      }
    });
  },
  components: {
    bottomTab, alert, DatePicker
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #f2f2f2;
  padding-bottom: 50px;
  overflow-y: scroll;
}

.topBC {
  height: 120px;
  width: 100%;
  background: red;
  background: url('../static/images/bc_charge.png') no-repeat center;
  text-align: center;
}

.topBC img {
  margin-top: 11px;
}

.topBC .title {
  height: 35px;
  width: 100%;
  margin-top: 15px;
}

.title span {
  display: block;
  font-size: 30px;
  text-align: center;
  color: white;
}

.title img {
  width: 28px;
  height: 35px;
  float: right;
  margin-top: -45px;
  margin-right: 95px;
}

.title .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  color: white;
  margin-left: 5%;
  margin-top: -100px;
  border-radius: 3px;
}

.context {
  width: 100%;
  padding-top: 1px;
  border-radius: 10px;
  padding-bottom: 30px;
}

.context .area_search {
  margin-top: 5px;
  background: #FFFFFF;
  padding-bottom: 10px;
  width: 100%;
  overflow: auto;
}

.area_search .phone {
  margin-top: 10px;
  height: 40px;
  border-radius: 15px;
  outline: none;
  border: 1px solid #C9C9C9;
  width: 60%;
  padding-left: 10px;
  margin-left: 20%;
  background-image: url("../static/images/icon_charge_search.png"); /*设置图片的地址*/
  background-size: 25px 25px; /*这里是图片的大小*/
  background-repeat: no-repeat; /*让图片不重复*/
  background-position: 95% 5px; /*图片在input中的位置  离左边的距离和离右边的距离*/
}


.area_search .memberList {
  border-top: #f2f2f2 solid 1px;
  width: 100%;
}

.memberList li {
  width: 50%;
  height: 50px;
  float: left;
  line-height: 60px;
  text-align: center;
}

.memberList li input {
  border-color: orange;
  height: 16px;
  width: 16px;
  float: left;
  margin-top: 20px;
  margin-left: 17%;
}

.memberList li span {
  font-size: 14px;
  color: white;
  height: 25px;
  display: block;
  border-radius: 15px;
  line-height: 25px;
  padding-left: 10px;
  margin-top: 16px;
  margin-left: 10px;
  padding-right: 10px;
  background: orange;
  float: left;
}

.memberList li em {
  margin-left: 10px;
  display: block;
  margin-top: -2px;
  font-size: 14px;
  color: #4E4E4E;
  height: 30px;
  float: left;
}

.context .area_tab {
  margin-top: 5px;
  background: #FFFFFF;
  height: 65px;
}

.area_tab ul {
  padding-top: 15px;
  height: 65px;
  overflow: hidden;
  overflow-x: auto; /* 设置x轴可滑动 */
  white-space: nowrap; /* 元素不换行 */
  width: auto; /* (宽度) */
  padding-left: 20px;
}

.area_tab ul li {
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  box-shadow: 1px 1px 11px #afafaf;
  background: #ffffff;
  text-align: center;
  color: #4E4E4E;
  font-size: 14px;
  margin-right: 20px;
  line-height: 35px;
  display: inline-block;
}


.area_tab ul .tab_select {
  padding-left: 5px;
  padding-right: 5px;
  height: 35px;
  border-radius: 4px;
  box-shadow: 1px 1px 11px #afafaf;
  background: #FFA310;
  text-align: center;
  color: white;
  margin-right: 20px;
  line-height: 35px;
  display: inline-block;
}


.context .area_member {
  margin-top: 10px;
  background: #FFFFFF;
  width: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

.area_member .banner {
  width: 100%;
  height: 30px;
  background: url('../static/images/banner_charge_member.png') no-repeat left;
}

.area_member .banner_hour {
  width: 100%;
  height: 30px;
  background: url('../static/images/banner_charge_hour.png') no-repeat left;
}

.area_member span {
  color: white;
  font-size: 16px;
  margin-left: 25px;
  line-height: 30px;
  float: left;
}

.area_member em {
  color: #C0C0C0;
  font-size: 13px;
  float: right;
  margin-right: 25px;
  line-height: 30px;
}

.context .chargeOption {
  width: 100%;
  margin-left: 1.3%;
}

.chargeOption li {
  height: 120px;
  width: 30%;
  float: left;
  border: #E6E6E6 solid 1px;
  border-radius: 9px;
  margin-top: 10px;
  margin-left: 2%;
  text-align: center;
}


.chargeOption .activeLi {
  height: 120px;
  width: 30%;
  float: left;
  border: #FFA310 solid 1px;
  background-color: #FFF6E6;
  border-radius: 9px;
  margin-top: 10px;
  margin-left: 2%;
  text-align: center;
}

.chargeOption li .month {
  font-size: 16px;
  height: 40px;
  display: block;
  width: 100%;
  color: #4E4E4E;
  line-height: 40px;
  float: none;
}

.chargeOption li .cash {
  font-size: 18px;
  display: block;
  width: 100%;
  height: 40px;
  color: #FFA310;
  line-height: 42px;
  float: none;
}

.chargeOption li .cash em {
  font-size: 24px;
  color: #FFA310;
  margin-left: -15px;
  line-height: 40px;
}

.chargeOption li .gift {
  font-size: 14px;
  height: 38px;
  background-color: #FAFAFA;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  color: #B6B6B6;
  float: none;
  line-height: 40px;
}

.chargeOption li .cashActive {
  font-size: 18px;
  display: block;
  width: 100%;
  height: 40px;
  color: #FF0000;
  line-height: 42px;
  float: none;
}

.chargeOption li .cash .emActive {
  font-size: 24px;
  color: #FF0000;
  margin-left: -15px;
  line-height: 40px;
}

.chargeOption li .giftActive {
  font-size: 14px;
  height: 38px;
  background-color: #FFA310;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  color: #FFFFFF;
  float: none;
  line-height: 40px;
}

.chargeOption li .dateExpire {
  font-size: 12px;
  height: 38px;
  background-color: #FAFAFA;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  color: #B6B6B6;
  float: none;
  line-height: 40px;
}

.chargeOption li .dateExpireActive {
  font-size: 12px;
  height: 38px;
  background-color: #FFA310;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  color: #FFFFFF;
  float: none;
  line-height: 40px;
}

.context .area_account {
  margin-top: 5px;
  background: #FFFFFF;
  width: 100%;
  overflow: auto;
}

.context .area_account .title {
  font-size: 20px;
  color: #363636;
  height: 60px;
  width: 22%;
  margin-left: 3%;
  margin-top: 30px;
  float: left;
}

.context .area_account .right_cash {
  font-size: 30px;
  width: 40%;
  height: 85px;
  line-height: 30px;
  color: #FF5800;
  margin-top: 25px;
  padding-left: 2%;
  float: left;
}

.area_account .right_cash .money {
  font-size: 43px;
}

.area_account .right_cash .memo {
  font-size: 12px;
  display: block;
  color: #B6B6B6;
  margin-left: 20px;
}

.context .area_account .right_code {
  font-size: 14px;
  height: 85px;
  width: 35%;
  float: right;
  margin-top: 30px;
  color: #FF5800;
}

.right_code .inviteCode {
  font-size: 14px;
  outline: none;
  color: #b2b2b2;
  border: none;
}

.right_code em {
  color: #b2b2b2;
  font-size: 12px;
  margin-left: 2px;
}


.area_account .button {
  height: 40px;
  background: #FFA310;
  width: 85%;
  font-size: 22px;
  color: white;
  border-radius: 23px;
  text-align: center;
  line-height: 40px;
  margin: 120px auto 20px;
}

.area_account .chargeExplain {
  margin-left: 12%;
  margin-top: 30px;
  padding-bottom: 40px;
}

.chargeExplain p {
  height: 8px;
  color: #363636;
}

::-webkit-input-placeholder {
  color: #ffb8d9;
}

/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
